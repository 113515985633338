import { FC } from 'react'
import { Spinner } from './Spinner'

export const FullScreenSpinner: FC<React.PropsWithChildren> = () => {
  return (
    <div className='bg-background fixed inset-0 z-50 flex items-center justify-center'>
      <div className='animate-[fade-in_0.3s_3s_ease-in-out_forwards] opacity-0'>
        <Spinner size={24} />
      </div>
    </div>
  )
}
