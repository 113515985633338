import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'

import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../utils'

const Tabs = TabsPrimitive.Root

const listVariants = cva('inline-flex h-9 items-center justify-center text-muted-foreground', {
  variants: {
    variant: {
      default: 'rounded-lg bg-muted p-1 ',
      plain: 'bg-transparent p-0 gap-2',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

type TabsListProps = React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & VariantProps<typeof listVariants>

const TabsList = React.forwardRef<React.ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
  ({ variant, className, ...props }, ref) => (
    <TabsPrimitive.List ref={ref} className={cn(listVariants({ variant, className }))} {...props} />
  ),
)
TabsList.displayName = TabsPrimitive.List.displayName

const triggerVariants = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'whitespace-nowrap',
    'bg-transparent',
    'px-3',
    'py-1',
    'text-sm',
    'font-normal',
    'ring-offset-background',
    'transition-all',
    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-ring',
    'focus-visible:ring-offset-2',
    'disabled:pointer-events-none',
    'disabled:opacity-50',
    'data-[state=active]:bg-background',
    'data-[state=active]:text-foreground',
    'data-[state=active]:shadow',
  ],
  {
    variants: {
      variant: {
        default: 'rounded-md',
        oval: 'rounded-full border border-transparent data-[state=active]:border-border',
      },
    },
  },
)

type TabsTriggerProps = React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> &
  VariantProps<typeof triggerVariants>

const TabsTrigger = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, TabsTriggerProps>(
  ({ variant, className, ...props }, ref) => (
    <TabsPrimitive.Trigger ref={ref} className={cn(triggerVariants({ variant, className }))} {...props} />
  ),
)
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-background focus-visible:ring-ring mt-4 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
