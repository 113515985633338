import { KeyboardEvent, useCallback, useRef, useState } from 'react'

import { isValidEmail } from '@thenarrative/common'
import { Command as CommandPrimitive } from 'cmdk'
import { FC } from 'react'
import { cn } from '../utils'
import { Badge } from './Badge'
import { Command, CommandGroup, CommandItem } from './Command'
import { Flexbox } from './layout'

export type InputMultiSelectValue = Record<'value' | 'label', string>

type Props = {
  allowNonListValues?: boolean
  className?: string
  listValues: InputMultiSelectValue[]
  nonListPlaceholder?: string
  placeholder?: string
  selected: InputMultiSelectValue[]
  onSelectedChange: (selected: InputMultiSelectValue[], latestItem?: InputMultiSelectValue) => void
  onValueChange?: (value: string) => void
}

export const InputMultiselect: FC<Props> = ({
  allowNonListValues = false,
  className,
  listValues,
  nonListPlaceholder = '',
  placeholder = 'Select items...',
  selected,
  onSelectedChange,
  onValueChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const commandGroupRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleUnselect = useCallback(
    (item: InputMultiSelectValue) => () => {
      const updatedSelected = selected.filter((s) => {
        return s.value !== item.value
      })

      onSelectedChange(updatedSelected)
    },
    [onSelectedChange, selected],
  )

  const handleOnValueChange = useCallback(
    (value: string) => {
      onValueChange?.(value)
      setInputValue(value)
    },
    [onValueChange],
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            const newSelected = [...selected]
            newSelected.pop()
            onSelectedChange(newSelected)
          }
        }

        if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()

          const value = input.value.trim()
          const validEmail = isValidEmail(value)

          if (!validEmail) return

          onSelectedChange([...selected, { value, label: value }])

          handleOnValueChange('')
        }

        // This is not a default behaviour of the <input /> field
        if (e.key === 'Escape') {
          input.blur()
        }
      }
    },
    [handleOnValueChange, onSelectedChange, selected],
  )

  const handleContainerClick = useCallback(() => {
    inputRef.current?.focus()
  }, [])

  const handleOpenChange = (open: boolean) => () => {
    setOpen(open)
  }

  const selectables = listValues.filter((listValue) => {
    return !selected.some((selectedValue) => selectedValue.value === listValue.value)
  })

  if (allowNonListValues && inputValue.length) {
    selectables.push({
      value: inputValue,
      label: `${nonListPlaceholder} ${inputValue}`,
    })
  }

  return (
    <Command onKeyDown={handleKeyDown} className='overflow-visible bg-transparent'>
      <div
        onClick={handleContainerClick}
        className={cn(
          'min-h-input-md border-input shadow-input hover:[&:not(:focus-within)]:outline-input-hover focus-within:ring-input-ring focus-within:outline-input-active group flex items-center rounded-md border p-1 text-sm leading-none focus-within:ring-2',
          className,
        )}
      >
        <Flexbox align='center' gap={1} grow wrap>
          {selected.map((item) => {
            return (
              <Badge key={item.value} variant='outline' className='gap-1' onDismiss={handleUnselect(item)} dismissable>
                {item.label}
              </Badge>
            )
          })}
          <CommandPrimitive.Input
            autoFocus={false}
            ref={inputRef}
            value={inputValue}
            onValueChange={handleOnValueChange}
            onBlur={handleOpenChange(false)}
            onFocus={handleOpenChange(true)}
            placeholder={placeholder}
            className={cn('placeholder:text-muted-foreground flex-1 bg-transparent p-2 outline-none')}
          />
        </Flexbox>
      </div>
      {open && !!selectables.length ? (
        <div className='relative'>
          <div className='bg-popover text-popover-foreground animate-in absolute top-1 z-10 w-full rounded-md outline-none'>
            <CommandGroup ref={commandGroupRef} className='command-group h-full overflow-auto rounded-md border'>
              {selectables.map((item) => {
                return (
                  <CommandItem
                    key={item.label}
                    value={`${item.label}:${item.value}`}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onSelect={() => {
                      handleOnValueChange('')
                      if (allowNonListValues && item.label.startsWith(nonListPlaceholder)) {
                        const tokens = item.label.split(`${nonListPlaceholder} `)
                        item.label = tokens[tokens.length - 1]
                      }
                      onSelectedChange([...selected, item], item)
                    }}
                    className='cursor-pointer'
                  >
                    {item.label}
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </div>
        </div>
      ) : null}
    </Command>
  )
}
