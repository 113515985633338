import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { Editor, BubbleMenu as TiptapBubbleMenu } from '@tiptap/react'
import { useState } from 'react'
import { Button } from './Button'
import { LinkInput } from './FormatBar'
import { Icon } from './Icon'

export type BubbleMenuProps = {
  editor: Editor | null
}

export const BubbleMenu = ({ editor }: BubbleMenuProps) => {
  if (!editor) {
    return null
  }
  const [isLinkPopoverOpen, setIsLinkPopoverOpen] = useState(false)

  const handleLinkPopoverChange = (open: boolean) => {
    setIsLinkPopoverOpen(open)
  }

  return (
    <TiptapBubbleMenu className='border-l-0' editor={editor}>
      <div className='border-border flex gap-1 rounded-lg bg-white p-0.5 shadow'>
        <Button
          onClick={() => editor?.chain().focus().toggleBold().run()}
          variant={'ghost'}
          className={`h-9 w-9 ${editor.isActive('bold') && 'bg-gray-100'}`}
          size={'icon'}
        >
          <Icon name='TextBolder' />
        </Button>

        <Button
          onClick={() => editor?.chain().focus().toggleItalic().run()}
          variant={'ghost'}
          size={'icon'}
          className={`h-9 w-9 ${editor.isActive('italic') && 'bg-gray-100'}`}
        >
          <Icon name='TextItalic' />
        </Button>

        <Button
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
          variant={'ghost'}
          size={'icon'}
          className={`h-9 w-9 ${editor.isActive('underline') && 'bg-gray-100'}`}
        >
          <Icon name='TextUnderline' />
        </Button>

        <Button
          onClick={() => editor?.chain().focus().toggleStrike().run()}
          variant={'ghost'}
          size={'icon'}
          className={`h-9 w-9 ${editor.isActive('strike') && 'bg-gray-100'}`}
        >
          <Icon name='TextStrikethrough' />
        </Button>

        <Popover onOpenChange={handleLinkPopoverChange} open={isLinkPopoverOpen}>
          <PopoverTrigger asChild>
            <Button
              size={'icon'}
              variant={'ghost'}
              className={`h-9 w-9 ${editor.isActive('link') ? 'bg-gray-100' : ''}`}
            >
              <Icon name='TextLink' />
            </Button>
          </PopoverTrigger>
          <PopoverContent side='top' sideOffset={8} align='start'>
            <LinkInput editor={editor} />
          </PopoverContent>
        </Popover>

        <Button
          onClick={() => editor?.chain().focus().extendMarkRange('link').unsetLink().run()}
          variant={'ghost'}
          size={'icon'}
          className={`h-9 w-9`}
        >
          <Icon name='TextUnlink' />
        </Button>
      </div>
    </TiptapBubbleMenu>
  )
}

export default BubbleMenu
