import { VariantProps, cva } from 'class-variance-authority'
import { FC, PropsWithChildren } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const variants = cva('px-3 py-1.5 rounded-md max-w-[70%] leading-1 text-sm text-wrap whitespace-pre-line', {
  variants: {
    type: {
      human: 'bg-gray-200 self-end border border-gray-300',
      ai: 'border border-blue-400 bg-blue-300 self-start',
      function: 'border border-green-400 bg-green-300 self-start',
      system: 'w-full text-center',
      tool: 'border border-violet-400 bg-violet-300 self-start',
    },
  },
})

type Props = PropsWithChildren<{
  date?: Date
}> &
  VariantProps<typeof variants>

interface NodeProps {
  node?: any
  [key: string]: any
}

export const MessageBubble: FC<Props> = ({ children, date, type }) => {
  const components = {
    h1: ({ node, ...props }: NodeProps) => <h1 className='text-xl font-bold' {...props} />,
    h2: ({ node, ...props }: NodeProps) => <h2 className='text-m font-bold' {...props} />,
    ul: ({ node, ...props }: NodeProps) => <ul className='list-inside list-disc pl-4' {...props} />,
    ol: ({ node, ...props }: NodeProps) => <ol className='list-inside list-decimal pl-4' {...props} />,
    li: ({ node, ...props }: NodeProps) => <li className='mb-1' {...props} />,
    strong: ({ node, ...props }: NodeProps) => <strong className='font-bold' {...props} />,
    em: ({ node, ...props }: NodeProps) => <em className='italic' {...props} />,
    pre: ({ node, ...props }: NodeProps) => <pre className='overflow-auto' {...props} />,
  }

  return (
    <ReactMarkdown className={variants({ type })} remarkPlugins={[remarkGfm]} components={components}>
      {children as string}
    </ReactMarkdown>
  )
}
