import { SignedIn, SignedOut } from '@clerk/nextjs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@thenarrative/tailwind-config/globals.css'
import { Toaster } from '@thenarrative/ui'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { ReactElement, ReactNode, useState } from 'react'

import { AuthProvider } from '../contexts/AuthProvider'
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext'
import { SessionProvider } from '../contexts/SessionContext'
import { IntercomProvider } from '../utils/intercom/IntercomProvider'

import { ErrorBoundary } from '@sentry/nextjs'
import { ErrorLayout } from '../components/layout/ErrorLayout'

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function App(props: AppPropsWithLayout) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <FeatureFlagProvider>
      <QueryClientProvider client={queryClient}>
        <IntercomProvider>
          <SignedIn>
            <SignedInApp {...props} />
          </SignedIn>
          <SignedOut>
            <SignedOutApp {...props} />
          </SignedOut>
        </IntercomProvider>
        <Toaster />
      </QueryClientProvider>
    </FeatureFlagProvider>
  )
}

export default function WrappedInAuthApp(props: AppPropsWithLayout) {
  return (
    <AuthProvider>
      <App {...props} />
    </AuthProvider>
  )
}

const mainClassName = `h-full`

const SignedInApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  return (
    <ErrorBoundary fallback={<ErrorLayout />}>
      <SessionProvider>
        <div className={mainClassName}>{getLayout(<Component {...pageProps} />)}</div>
      </SessionProvider>
    </ErrorBoundary>
  )
}

const SignedOutApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  return getLayout(<Component {...pageProps} className={mainClassName} />)
}
