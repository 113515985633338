import {
  UserPlus2Icon as AddUser,
  AlertTriangle,
  AlignRight,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowLeftRight,
  ArrowLeftToLine,
  ArrowRight,
  ArrowRightToLine,
  ArrowUp,
  ArrowUpDown,
  ArrowUpRight,
  FilePlus2 as AssetAdded,
  UserPlus2 as AssignUser,
  Banknote,
  Bold,
  Briefcase,
  Building2,
  Calendar,
  CalendarClock,
  CalendarMinus,
  CalendarPlus,
  CalendarX2,
  CaseSensitive,
  Check,
  CheckCircle2 as CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  CircleDot,
  X as Close,
  Cog,
  Coins,
  CheckCircle2 as Completed,
  Copy,
  CornerDownRight,
  CreditCard,
  Crown,
  DollarSign,
  Dot,
  ArrowDownToLine as Download,
  Edit2 as Edit,
  Edit3 as EditWithUnderline,
  FileCheck,
  FileImage,
  FilePlus2Icon as FilePlus,
  FileSearch,
  FileText,
  ListFilter as Filter,
  Flag,
  Glasses,
  Globe,
  Hash,
  HelpCircle,
  Home,
  Hourglass,
  Image,
  Inbox,
  Indent,
  Info,
  Unplug as Integrations,
  Italic,
  KanbanSquare,
  Landmark,
  DoorOpen as Leave,
  Link2,
  List,
  List as ListBullets,
  ListOrdered as ListNumbers,
  ListOrdered,
  ListTodo,
  Lock,
  LucideProps,
  Mail,
  Users2 as Members,
  MessageCircle,
  MessageSquare,
  Minus,
  MoreHorizontal,
  MoreVertical,
  MountainSnow,
  ArrowRightLeft as Move,
  Bell as Notifications,
  Outdent,
  Paperclip,
  PenSquare,
  Percent,
  Play,
  Plus,
  RefreshCw,
  ReplyAll,
  CornerDownLeft as Return,
  Search,
  Send,
  BarChart as SetTaskPriority,
  AreaChart as SetTaskStatus,
  Settings,
  Share,
  SlidersHorizontal as SlidersH,
  Sparkles,
  Loader2 as Spinner,
  SquareGanttChart as SquareGantt,
  Strikethrough,
  Tag,
  Tags,
  Shapes as Templates,
  Bold as TextBolder,
  Italic as TextItalic,
  Link as TextLink,
  Strikethrough as TextStrikethrough,
  Underline as TextUnderline,
  Unlink as TextUnlink,
  History as TimerRewind,
  ToggleLeft,
  ToggleRight,
  Trash2 as Trash,
  Truck,
  TypeIcon,
  UserMinus2 as UnAssignUser,
  Underline,
  BadgeHelp as Unknown,
  Unlock,
  FileInput as UploadAsset,
  User,
  UserCog,
  Users,
  Video,
  Warehouse,
  Wine,
  GitBranchPlus as Workflows,
} from 'lucide-react'
import type { CSSProperties } from 'react'
import React, { lazy } from 'react'

const GoogleDriveLogo = lazy(() => import('../icons/google-drive.svg'))
const MicrosoftWordLogo = lazy(() => import('../icons/microsoft-word.svg'))
const NarrativeLogo = lazy(() => import('../icons/narrative-logo.svg'))
const PDFLogo = lazy(() => import('../icons/pdf-logo.svg'))
const PriorityNone = lazy(() => import('../icons/priority-none.svg'))
const PriorityLow = lazy(() => import('../icons/priority-low.svg'))
const PriorityMedium = lazy(() => import('../icons/priority-medium.svg'))
const PriorityHigh = lazy(() => import('../icons/priority-high.svg'))
const HealthGood = lazy(() => import('../icons/health-score/health.svg'))
const HealthLow = lazy(() => import('../icons/health-score/low.svg'))
const HealthRisk = lazy(() => import('../icons/health-score/risk.svg'))
const HealthCritical = lazy(() => import('../icons/health-score/critical.svg'))
const CheckCircleFilled = lazy(() => import('../icons/check-circle-filled.svg'))
const SlackLogo = lazy(() => import('../icons/slack.svg'))
const EmailLogo = lazy(() => import('../icons/email.svg'))
const InProgress = lazy(() => import('../icons/in-progress.svg'))
const Folder = lazy(() => import('../icons/folder.svg'))
const AddComment = lazy(() => import('../icons/add-comment.svg'))
const AILogo = lazy(() => import('../icons/ai.svg'))
const AIChat = lazy(() => import('../icons/ai-chat.svg'))
const Handshake = lazy(() => import('../icons/handshake.svg'))
const Theater = lazy(() => import('../icons/theater.svg'))
const Programs = lazy(() => import('../icons/programs.svg'))
const VideoPlay = lazy(() => import('../icons/video-play.svg'))
const VideoPause = lazy(() => import('../icons/video-pause.svg'))
const VideoStepBack5 = lazy(() => import('../icons/video-step-back-5.svg'))
const VideoStepForward5 = lazy(() => import('../icons/video-step-forward-5.svg'))
const VideoVolume = lazy(() => import('../icons/video-volume.svg'))
const VideoVolumeMute = lazy(() => import('../icons/video-volume-mute.svg'))

const RotateRight = lazy(() => import('../icons/rotate-right.svg'))
const RotateLeft = lazy(() => import('../icons/rotate-left.svg'))

const AIAnalysis = lazy(() => import('../icons/ai-analysis.svg'))

export const icons = {
  Download,
  AddUser,
  AIAnalysis,
  AIChat,
  AlertTriangle,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowLeftRight,
  ArrowRight,
  ArrowUp,
  ArrowUpDown,
  ArrowUpRight,
  ArrowLeftToLine,
  ArrowRightToLine,
  AssetAdded,
  AssignUser,
  Banknote,
  Briefcase,
  Building2,
  Calendar,
  CalendarClock,
  CalendarX2,
  Check,
  CheckCircle,
  CheckCircleFilled,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  CircleDot,
  Close,
  Coins,
  Cog,
  Completed,
  Copy,
  CornerDownRight,
  CreditCard,
  Crown,
  DollarSign,
  Dot,
  Edit,
  EditWithUnderline,
  EmailLogo,
  FileImage,
  FilePlus,
  FileSearch,
  FileText,
  Filter,
  Folder,
  Glasses,
  GoogleDriveLogo,
  HealthGood,
  HealthLow,
  HealthRisk,
  HealthCritical,
  HelpCircle,
  Home,
  Hourglass,
  Inbox,
  Info,
  InProgress,
  Integrations,
  Landmark,
  Leave,
  Link2,
  ListBullets,
  ListNumbers,
  ListTodo,
  Lock,
  Mail,
  Members,
  MessageCircle,
  MicrosoftWordLogo,
  MoreHorizontal,
  MoreVertical,
  MountainSnow,
  Move,
  NarrativeLogo,
  Notifications,
  PDFLogo,
  Percent,
  Play,
  Plus,
  PriorityHigh,
  PriorityLow,
  PriorityMedium,
  PriorityNone,
  Programs,
  Return,
  Search,
  Send,
  SetTaskPriority,
  SetTaskStatus,
  Settings,
  Share,
  SlackLogo,
  SlidersH,
  Sparkles,
  Spinner,
  SquareGantt,
  PenSquare,
  Templates,
  TextBolder,
  TextItalic,
  TextLink,
  TextStrikethrough,
  Theater,
  TimerRewind,
  Truck,
  TextUnderline,
  TextUnlink,
  UnAssignUser,
  Unlock,
  UploadAsset,
  User,
  Users,
  ViewColumn: KanbanSquare,
  Warehouse,
  Wine,
  Workflows,
  Paperclip,
  UserCog,
  Image,
  CalendarMinus,
  AlignRight,
  CalendarPlus,
  FileCheck,
  MessageSquare,
  Bold,
  Italic,
  Underline,
  Strikethrough,
  List,
  ListOrdered,
  Indent,
  Outdent,
  ReplyAll,
  AILogo,
  Handshake,
  AddComment,
  Video,
  VideoPlay,
  VideoPause,
  VideoStepBack5,
  VideoStepForward5,
  VideoVolume,
  VideoVolumeMute,
  Trash,
  RotateRight,
  RotateLeft,
  ToggleLeft,
  ToggleRight,
  Minus,
  TypeIcon,
  CaseSensitive,
  Hash,
  RefreshCw,
  Tag,
  Tags,
  Unknown,
  Globe,
  Flag,
}

export type IconNames = keyof typeof icons

export type IconProps = LucideProps & {
  name: IconNames
  className?: string
  onClick?: () => void
  width?: number | 'auto'
  height?: number | 'auto'
  style?: CSSProperties
}

export const Icon: React.FC<IconProps> = (props) => {
  const { name, width = 16, ...rest } = props

  const IconComponent = icons[name]

  return (
    <React.Suspense fallback={<div className={props.className} />}>
      <IconComponent width={width} {...rest} />
    </React.Suspense>
  )
}
