import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from '../utils'
import { Icon, IconNames } from './Icon'
import { Slot } from './Slot'
import { Spinner } from './Spinner'

const buttonVariants = cva(
  [
    'inline-flex',
    'gap-2',
    'shrink-0',
    'items-center',
    'justify-center',
    'rounded-md',
    'text-sm',
    'font-medium',
    'ring-offset-background',
    'transition-colors',
    'transition-background',
    'shadow-button',
    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-ring',
    'focus-visible:ring-offset-2',
    'disabled:pointer-events-none',
    'disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        default: 'bg-button-primary bg-h-2x bg-pos-0 border border-primary text-primary-foreground hover:bg-pos-y-100',
        secondary:
          'bg-gradient-to-t from-slate-200 border border-border hover:from-slate-300 hover:to-slate-100 hover:border-border-hover',
        info: 'bg-neutral-100 text-neutral-600 hover:bg-neutral-200',
        control: 'bg-transparent hover:bg-foreground',
        constructive: 'text-brand-foreground bg-emerald-700 hover:bg-emerald-800 focus:ring-emerald-900',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive-muted',
        link: 'text-primary underline-offset-4 hover:underline',
        ghost: 'hover:bg-accent hover:text-accent-foreground shadow-none',
        ghostMuted: 'text-muted-foreground hover:bg-muted shadow-none',
        ghostInteractive: 'text-interactive hover:bg-interactive-muted shadow-none',
        ghostDestructive: 'text-destructive hover:bg-red-300 shadow-none',
        brand: 'bg-brand text-brand-foreground border border-brand hover:bg-interactive',
        dashed:
          'border border-dashed border-input bg-background hover:bg-accent hover:text-accent-foreground shadow-none',
        interactive: 'bg-interactive-muted text-interactive hover:bg-interactive-muted border border-interactive',
        magic: 'bg-magic text-magic-foreground hover:bg-magic-muted border border-magic',
        input: 'border border-input shadow-input hover:bg-muted',
      },
      size: {
        default: 'h-input-md px-3 py-2',
        xs: 'h-input-xs px-2 py-1',
        sm: 'h-input-sm rounded-md px-2',
        lg: 'h-input-lg rounded-md px-6',
        icon: 'h-auto w-auto',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export type ButtonVariants = VariantProps<typeof buttonVariants>

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ButtonVariants {
  asChild?: boolean
  leftIcon?: IconNames
  leftIconClassName?: string
  rightIcon?: IconNames
  rightIconClassName?: string
  isPending?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isPending = false,
      disabled,
      leftIcon,
      rightIcon,
      leftIconClassName,
      rightIconClassName,
      asChild = false,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        disabled={disabled || isPending}
        {...props}
      >
        {isPending ? (
          <Spinner />
        ) : (
          <>
            {leftIcon && <Icon name={leftIcon} className={leftIconClassName} />}
            {children}
            {rightIcon && <Icon name={rightIcon} className={rightIconClassName} />}
          </>
        )}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
