import { CalendarIcon } from '@radix-ui/react-icons'
import * as React from 'react'

import { SelectSingleEventHandler } from 'react-day-picker'
import { cn, getNarrativeFormattedDate } from '../utils'
import { Button, ButtonVariants } from './Button'
import { Calendar } from './Calendar'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'

type AllowedVariants = ButtonVariants['variant']
type Props = {
  triggerSize?: ButtonVariants['size']
  triggerVariant?: 'input' | 'ghost'
  selected?: Date | undefined
  onSelect?: (date: Date | undefined) => void
  className?: string
}

export const DatePicker: React.FC<Props> = ({
  triggerSize,
  triggerVariant = 'ghost',
  selected,
  onSelect,
  className,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(selected)

  const handleOnSelect: SelectSingleEventHandler = (date) => {
    setSelectedDate(date)
    onSelect?.(date)
    setIsPopoverOpen(false)
  }

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          size={triggerSize}
          variant={triggerVariant}
          className={cn(
            'w-[240px] justify-start px-3 text-left font-normal',
            !selected && 'text-muted-foreground',
            className,
          )}
        >
          <CalendarIcon className='mr-2 h-4 w-4' />
          <span>{selectedDate ? getNarrativeFormattedDate(selectedDate.toDateString()) : 'Pick a date'}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0' align='start'>
        <Calendar
          mode='single'
          selected={selected}
          onSelect={handleOnSelect}
          initialFocus
          disabled={{ before: new Date() }}
        />
      </PopoverContent>
    </Popover>
  )
}
