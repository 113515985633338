import { CaretSortIcon } from '@radix-ui/react-icons'
import { Column } from '@tanstack/react-table'

import { cn } from '../utils'
import { Button } from './Button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './Dropdown'
import { Icon } from './Icon'

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  title: string
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn('text-xs', className)}>{title}</div>
  }

  return (
    <div className={cn('flex items-center', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant='ghost' size='sm' className='-ml-2 h-6 px-2 focus-visible:ring-transparent'>
            <span className='text-xs'>{title}</span>
            {column.getIsSorted() === 'desc' ? (
              <Icon name='ArrowDown' className='ml-2 h-3.5 w-3.5' />
            ) : column.getIsSorted() === 'asc' ? (
              <Icon name='ArrowUp' className='ml-2 h-3.5 w-3.5' />
            ) : (
              <CaretSortIcon className='ml-2 h-3.5 w-3.5' />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='start'>
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <Icon name='ArrowUp' className='text-muted-foreground mr-2 h-3.5 w-3.5' />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <Icon name='ArrowDown' className='text-muted-foreground mr-2 h-3.5 w-3.5' />
            Desc
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
