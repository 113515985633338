import { FC, PropsWithChildren } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { cn } from '../utils'

type Props = PropsWithChildren<{
  date?: Date
  h1ClassName?: string
  h2ClassName?: string
  ulClassName?: string
  olClassName?: string
  liClassName?: string
  strongClassName?: string
  emClassName?: string
  preClassName?: string
  pClassName?: string
}>

interface NodeProps {
  node?: any
  [key: string]: any
}

export const Markdown: FC<Props> = ({
  children,
  h1ClassName,
  h2ClassName,
  ulClassName,
  olClassName,
  liClassName,
  strongClassName,
  emClassName,
  preClassName,
  pClassName,
}) => {
  const components = {
    h1: ({ node, ...props }: NodeProps) => <h1 className={cn('text-xl font-bold', h1ClassName)} {...props} />,
    h2: ({ node, ...props }: NodeProps) => <h2 className={cn('text-m font-bold', h2ClassName)} {...props} />,
    ul: ({ node, ...props }: NodeProps) => <ul className={cn('list-inside list-disc pl-4', ulClassName)} {...props} />,
    ol: ({ node, ...props }: NodeProps) => (
      <ol className={cn('list-inside list-decimal pl-4', olClassName)} {...props} />
    ),
    li: ({ node, ...props }: NodeProps) => <li className={cn('mb-1', liClassName)} {...props} />,
    strong: ({ node, ...props }: NodeProps) => <strong className={cn('font-bold', strongClassName)} {...props} />,
    em: ({ node, ...props }: NodeProps) => <em className={cn('italic', emClassName)} {...props} />,
    pre: ({ node, ...props }: NodeProps) => <pre className={cn('overflow-auto', preClassName)} {...props} />,
    p: ({ node, ...props }: NodeProps) => <p className={cn('mb-2 text-base', pClassName)} {...props} />,
  }

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
      {children as string}
    </ReactMarkdown>
  )
}
