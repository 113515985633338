import { Fallback, Image, Root } from '@radix-ui/react-avatar'
import { cva } from 'class-variance-authority'
import { UserItem } from '../types'
import { cn } from '../utils'

type Props = {
  user: Pick<UserItem, 'id' | 'name' | 'avatarUrl'>
  size?: 'xsmall' | 'small' | 'medium'
  fallbackDelay?: number
}

const userAvatarVariants = cva('inline-flex items-center justify-center overflow-hidden', {
  variants: {
    size: {
      xsmall: 'w-4 h-4',
      small: 'w-6 h-6',
      medium: 'w-8 h-8',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

export const UserAvatar = ({ user, size = 'medium', fallbackDelay = 600 }: Props) => {
  return (
    <Root className={cn(userAvatarVariants({ size }))}>
      <Image src={user.avatarUrl} className='fit-cover h-full w-full rounded-full' alt={user.name} />
      <Fallback
        className='bg-border text-foreground flex h-full w-full items-center justify-center rounded rounded-full font-medium uppercase'
        delayMs={fallbackDelay}
      >
        {user.name?.charAt(0)}
      </Fallback>
    </Root>
  )
}
