import { ClerkProvider } from '@clerk/nextjs'
import { buttonVariants, cn, inputStyles } from '@thenarrative/ui'
import { FC, PropsWithChildren } from 'react'
import { ApplicationEnvironment } from '../utils/ApplicationEnvironment'

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => (
  <ClerkProvider
    publishableKey={ApplicationEnvironment.CLERK_PUBLISHABLE_KEY}
    appearance={{
      elements: {
        card: 'rounded-md border-none overflow-hidden',
        formButtonPrimary: cn(buttonVariants({ variant: 'default', size: 'default', className: 'capitalize' })),
        formFieldInput: cn(inputStyles, 'ring-offset-2 ring-2 ring-transparent'),
        scrollBox: 'rounded-none',
      },
    }}
    localization={{
      signIn: {
        start: {
          title: 'Welcome back!',
          subtitle: "Sign in to join your organization's workspace.",
        },
      },
      signUp: {
        start: {
          title: 'Create Account',
          subtitle: "Sign in to join your organization's workspace.",
        },
      },
    }}
  >
    {children}
  </ClerkProvider>
)
