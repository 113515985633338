import { Flexbox, Icon } from '@thenarrative/ui'
import Link from 'next/link'
import { FC, PropsWithChildren } from 'react'

type Props = {
  rightHeaderElement?: React.ReactNode
}

export const SignedOutLayout: FC<PropsWithChildren<Props>> = ({ rightHeaderElement, children }) => (
  <Flexbox height='full' column>
    <Flexbox
      as='header'
      justify='between'
      align='center'
      className='bg-background fixed left-0 right-0 top-0 z-10 h-14 border-b px-8'
    >
      <Link href='/'>
        <Icon width={24} name='NarrativeLogo' />
      </Link>
      {rightHeaderElement}
    </Flexbox>
    {children}
  </Flexbox>
)
