import { PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { Editor } from '@tiptap/react'
import { FC, useState } from 'react'
import { cn } from '../utils'
import { Button } from './Button'
import { Icon } from './Icon'
import { ICON_SIZE, IconButton } from './IconButton'
import { Input } from './Input'
import { Popover } from './Popover'

export type FormatBarProps = {
  editor: Editor | null
  withHeadings?: boolean
  className?: string
  allowFiles?: boolean
  onSendClick?: () => void
  onFilesUpload?: (files: FileList) => void
}

export const FormatBar: FC<FormatBarProps> = ({ editor, className, allowFiles, onSendClick, onFilesUpload }) => {
  const [isLinkPopoverOpen, setIsLinkPopoverOpen] = useState(false)

  if (!editor) {
    return null
  }

  const handleLinkPopoverChange = (open: boolean) => {
    setIsLinkPopoverOpen(open)
  }

  return (
    <section className={cn('flex items-center justify-between gap-2 p-1.5', className)}>
      <div className='flex gap-2'>
        <IconButton onClick={() => editor?.chain().focus().toggleBold().run()} isActive={editor.isActive('bold')}>
          <Icon name='TextBolder' width={ICON_SIZE} height={ICON_SIZE} />
        </IconButton>
        <IconButton onClick={() => editor?.chain().focus().toggleItalic().run()} isActive={editor.isActive('italic')}>
          <Icon name='TextItalic' width={ICON_SIZE} height={ICON_SIZE} />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
          isActive={editor.isActive('underline')}
        >
          <Icon name='TextUnderline' width={ICON_SIZE} height={ICON_SIZE} />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive('bulletList')}
        >
          <Icon name='ListBullets' width={ICON_SIZE} height={ICON_SIZE} />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive('orderedList')}
        >
          <Icon name='ListNumbers' width={ICON_SIZE} height={ICON_SIZE} />
        </IconButton>

        <Popover onOpenChange={handleLinkPopoverChange} open={isLinkPopoverOpen}>
          <PopoverTrigger asChild>
            <IconButton isActive={editor?.isActive('link')}>
              <Icon name='TextLink' />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent side='top' sideOffset={8} align='start'>
            <LinkInput editor={editor} />
          </PopoverContent>
        </Popover>

        <IconButton onClick={() => editor?.chain().focus().extendMarkRange('link').unsetLink().run()}>
          <Icon name='TextUnlink' width={ICON_SIZE} height={ICON_SIZE} />
        </IconButton>
      </div>

      {onSendClick && (
        <IconButton onClick={onSendClick}>
          <Icon name='Send' width={ICON_SIZE} height={ICON_SIZE} />
        </IconButton>
      )}
      {allowFiles && (
        <Button size='sm' variant='secondary' leftIcon='Plus' className='relative'>
          Upload files
          <input
            value=''
            type='file'
            accept='.doc, .docx, image/*, video/*, .zip, .pdf, .xls, .xlsx'
            className=' absolute inset-0 cursor-pointer text-[0px] opacity-0'
            multiple
            onChange={(event) => {
              if (event.target.files && onFilesUpload) {
                onFilesUpload(event.target.files)
              }
            }}
          />
        </Button>
      )}
    </section>
  )
}

type LinkInputProps = {
  editor: Editor | null
}

export const LinkInput: FC<LinkInputProps> = ({ editor }) => {
  const [value, setValue] = useState<string>(editor?.getAttributes('link').href ?? '')

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event
    switch (key) {
      case 'Enter': {
        event.preventDefault()
        handleSaveLinkClick()
      }
    }
  }

  const handleSaveLinkClick = () => {
    if (!value) {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run()
      return
    }

    let href = value

    // make sure all links start with https:// and convert http:// to https://
    if (href.startsWith('http://')) {
      href = href.replace('http://', 'https://')
    }

    if (!href.startsWith('https://')) {
      href = `https://${href}`
    }

    editor?.chain().focus().extendMarkRange('link').setLink({ href }).run()
  }

  return (
    <div className='relative flex gap-2' onKeyDown={handleKeyDown}>
      <Input autoFocus className='pr-9' onChange={(e) => setValue(e.target.value)} value={value} />
      <div className='absolute right-1.5 top-1.5 justify-end gap-2'>
        {value?.length > 0 && (
          <Button type='button' className='h-7 w-7 p-0' variant='secondary' onClick={handleSaveLinkClick}>
            <Icon name='Return' width={16} height={16} />
          </Button>
        )}
      </div>
    </div>
  )
}
