export const ErrorLayout = () => {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center gap-4'>
      <h1 className='text-6xl'>Oops, something went wrong</h1>
      <div className='text-lg'>Our team has been notified of the issue and we are fixing it.</div>
      <div className='text-muted-foreground text-sm'>
        In the meantime, try refreshing the page or going to the homepage
      </div>
      <div className='mt-6 flex w-full items-center justify-center gap-4'>
        <button
          className='border-input bg-background hover:bg-accent hover:text-accent-foreground border px-2 py-1'
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
        <button
          className='border-input bg-background hover:bg-accent hover:text-accent-foreground border px-2 py-1'
          onClick={() => (window.location.href = '/')}
        >
          Home
        </button>
      </div>
    </div>
  )
}
