import { MimeType, WorkflowNodeInvocationType, WorkflowNodeType } from './enums'

export const LOCAL_STORAGE = {
  CURRENT_ORGANIZATION_URL: 'current-organization-url',
  PROJECT_VIEW_PREFERENCE: 'project-view-preference',
}

export const QUERY_PARAMS = {
  SETTINGS_OPEN: 'settings',
  SETTINGS_TAB: 'tab',
  SETTINGS_TAB_DETAILS: 'details',
  TEAM_SETTINGS_OPEN: 'team-settings',
  ONBOARDING_INVITES: 'onboarding-invites',
  PROJECTS_TAB: 'tab',
  PROJECTS_TASK_ID: 'taskId',
  REDIRECT: 'redirect',
}

export const NODE_WIDTH = 400
export const NODE_DEFAULT_POSITION = { x: 0, y: 0 }

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const HEADERS = {
  ORGANIZATION_ID: 'organization-id',
  MONO_API_KEY: 'mono-api-key',
} as const

export const allowedAvatarFileTypes = { png: 'image/png', jpg: 'image/jpg', jpeg: 'image/jpeg' }
export const allowedLogoFileTypes = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  webp: 'image/webp',
}

export const emojiBgColors = [
  { className: 'bg-red-100', code: '#FFDDD6' },
  { className: 'bg-orange-100', code: '#FFEDD5' },
  { className: 'bg-yellow-100', code: '#FEF9C3' },
  { className: 'bg-lime-100', code: '#ECFCCB' },
  { className: 'bg-green-100', code: '#DCFCE7' },
  { className: 'bg-cyan-100', code: '#CFFAFE' },
  { className: 'bg-sky-100', code: '#E0F2FE' },
  { className: 'bg-indigo-100', code: '#E0E7FF' },
  { className: 'bg-purple-100', code: '#F3E8FF' },
  { className: 'bg-pink-100', code: '#FCE7F3' },
  { className: 'bg-rose-100', code: '#FFE4E6' },
  { className: 'bg-gray-100', code: '#F3F4F6' },
]

export const DEFAULT_EMOJI = '💸'
export const DEFAULT_EMOJI_BG_COLOR = emojiBgColors[0].code

export const NO_COORDINATES = -10000

export const MAX_Z_INDEX = 9999999
export const MAX_DESCRIPTION_LENGTH = 48

export const mimeTypeMap: Record<MimeType, string> = {
  [MimeType.Doc]: 'application/msword',
  [MimeType.Docx]: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [MimeType.Text]: 'text/plain',
  [MimeType.Markdown]: 'text/markdown',
  [MimeType.Html]: 'text/html',
  [MimeType.Svg]: 'image/svg+xml',
  [MimeType.Png]: 'image/png',
  [MimeType.Jpg]: 'image/jpg',
  [MimeType.Jpeg]: 'image/jpeg',
  [MimeType.Webp]: 'image/webp',
  [MimeType.Pdf]: 'application/pdf',
  [MimeType.Zip]: 'application/zip',
  [MimeType.Mp4]: 'video/mp4',
  [MimeType.Webm]: 'video/webm',
  [MimeType.Mov]: 'video/quicktime',
  [MimeType.Csv]: 'application/csv',
  [MimeType.Rtf]: 'application/rtf',
  [MimeType.Mp3]: 'audio/mpeg',
  [MimeType.Tsv]: 'text/tab-separated-values',
  [MimeType.Wav]: 'audio/wav',
  [MimeType.Xls]: 'application/vnd.ms-excel',
  [MimeType.Xlsx]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const WINDOW_EVENT_TYPES = {
  RESIZE: 'resize',
  ORIENTATION_CHANGE: 'orientationchange',
  POINTER_MOVE: 'pointermove',
  POINTER_UP: 'pointerup',
  POINTER_DOWN: 'pointerdown',
  CLICK: 'click',
  CONTEXT_MENU: 'contextmenu',
  MOUSE_MOVE: 'mousemove',
  MOUSE_ENTER: 'mouseenter',
  MOUSE_UP: 'mouseup',
}

export const PLACE_STATE = {
  PLACING: 'placing',
  PLACED: 'placed',
  COMPLETE: 'complete',
} as const

export const LEVELS_HEADING = [1, 2, 3, 6]

export const InvocationTypeToNodeTypeMap = {
  [WorkflowNodeInvocationType.ACTION]: [
    WorkflowNodeType.NO_OP,
    WorkflowNodeType.ASSIGN_TASK,
    WorkflowNodeType.ADD_COLLABORATORS_TASK,
    WorkflowNodeType.UNASSIGN_TASK,
    WorkflowNodeType.SEND_EMAIL,
    WorkflowNodeType.SET_TASK_PRIORITY,
    WorkflowNodeType.SET_TASK_DATE,
    WorkflowNodeType.EXECUTION_DELAY,
    WorkflowNodeType.AI_REVIEW,
    WorkflowNodeType.NOTIFY_SLACK_CHANNEL,
    WorkflowNodeType.ARCHIVE_TASK,
    WorkflowNodeType.REMOVE_TASK_DATE,
  ],
  [WorkflowNodeInvocationType.TRIGGER]: [
    WorkflowNodeType.ON_TASK_ASSIGNED,
    WorkflowNodeType.ON_TASK_UNASSIGNED,
    WorkflowNodeType.ON_TASK_PRIORITY_SET,
    WorkflowNodeType.ON_TASK_STATUS_SET,
    WorkflowNodeType.ON_TASK_DATE_SET,
    WorkflowNodeType.ON_DOCUMENT_ASSET_HUMAN_REVIEWED,
    WorkflowNodeType.ON_TASK_COMMENT_ADDED,
    WorkflowNodeType.ON_TASK_ATTACHMENT_ADDED,
    WorkflowNodeType.ON_TASK_ASSIGNED_TO_SPECIFIC_USER,
  ],
}

export const WorkflowTriggerLabels: { [K in WorkflowNodeType]?: string } = {
  [WorkflowNodeType.ON_TASK_ASSIGNED]: 'Task Assignment',
  [WorkflowNodeType.ON_TASK_ASSIGNED_TO_SPECIFIC_USER]: 'Task Assignment to Specific User',
  [WorkflowNodeType.ON_TASK_UNASSIGNED]: 'unassigned this Task',
  [WorkflowNodeType.ON_TASK_PRIORITY_SET]: 'Set a Task Priority',
  [WorkflowNodeType.ON_TASK_STATUS_SET]: 'Set a Task Status',
  [WorkflowNodeType.ON_TASK_DATE_SET]: 'Set a Due Date',
  [WorkflowNodeType.ON_TASK_COMMENT_ADDED]: 'Task Comment Added',
  [WorkflowNodeType.ON_TASK_ATTACHMENT_ADDED]: 'Task Attachment Added',
  [WorkflowNodeType.ON_DOCUMENT_ASSET_HUMAN_REVIEWED]: 'Document Asset Human Reviewed',
}
