import { FC, PropsWithChildren } from 'react'

type Props = {
  wrapperStyles?: string
  hrStyles?: string
  contentStyles?: string
}

export const HRWithContent: FC<PropsWithChildren<Props>> = ({
  wrapperStyles = 'inline-flex items-center justify-center w-full my-5',
  hrStyles = 'w-11/12 h-px bg-gray-200',
  contentStyles = 'px-3 bg-white text-gray-500 text-sm',
  children,
}) => {
  if (!children) {
    return <hr className={`${hrStyles}`} />
  }

  return (
    <>
      <div className={`${wrapperStyles}`}>
        <hr className={`${hrStyles}`} />
        {children && <span className={`absolute left-1/2 -translate-x-1/2 ${contentStyles}`}>{children}</span>}
      </div>
    </>
  )
}
