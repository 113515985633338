import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronRight } from 'lucide-react'
import * as React from 'react'

import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../utils'
import { Icon } from './Icon'

const Accordion = AccordionPrimitive.Root

const itemVariants = cva(undefined, {
  variants: {
    border: {
      true: 'border-b',
    },
  },
  defaultVariants: {
    border: true,
  },
})

type AccordionItemProps = VariantProps<typeof itemVariants> &
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
const AccordionItem = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Item>, AccordionItemProps>(
  ({ border, className, ...props }, ref) => (
    <AccordionPrimitive.Item ref={ref} className={cn(itemVariants({ border }), className)} {...props} />
  ),
)
AccordionItem.displayName = 'AccordionItem'

type TriggerProps = {
  hideChevron?: boolean
} & React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
const AccordionTrigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, TriggerProps>(
  ({ hideChevron, className, children, ...props }, ref) => (
    <AccordionPrimitive.Trigger ref={ref} className={cn('flex items-center gap-2', className)} {...props}>
      {children}

      {!hideChevron && <Icon name='ChevronDown' className='' />}
    </AccordionPrimitive.Trigger>
  ),
)
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionTriggerChevron = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className='flex'>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-between py-4 font-medium transition-all [&[data-state=open]>.chevron]:rotate-90',
        className,
      )}
      {...props}
    >
      <ChevronRight className='chevron h-4 w-4 shrink-0 transition-transform duration-200' />
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTriggerChevron.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm transition-all',
      className,
    )}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionContent, AccordionItem, AccordionPrimitive, AccordionTrigger, AccordionTriggerChevron }
